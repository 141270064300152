import React, { useState } from 'react';
import './css/Export.css';
import { useDiskColor } from '../contexts/DiskColorContext';
import { useAudio } from '../contexts/AudioContext';
import { useImageContext } from '../contexts/ImageContext';

function Export() {
  const [isExporting, setIsExporting] = useState(false);
  const [userEmail, setUserEmail] = useState('');
  const { diskColor } = useDiskColor();
  const { audioData, updatedRegion } = useAudio();
  const { currentImage } = useImageContext();

  function createColoredCanvas() {
    const canvas = document.createElement('canvas');
    canvas.width = 800;
    canvas.height = 800;
    const ctx = canvas.getContext('2d');
    ctx.fillStyle = diskColor;
    ctx.fillRect(0, 0, canvas.width, canvas.height);
    return canvas;
  }

  function canvasToBlob(canvas) {
    return new Promise((resolve, reject) => {
      canvas.toBlob(blob => {
        if (blob) {
          resolve(blob);
        } else {
          reject(new Error('Canvas to Blob conversion failed'));
        }
      }, 'image/png');
    });
  }

  async function imageToBlob(imageSrc) {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => {
        const canvas = document.createElement('canvas');
        canvas.width = img.width;
        canvas.height = img.height;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0);
        canvas.toBlob(blob => {
          if (blob) {
            resolve(blob);
          } else {
            reject(new Error('Image to Blob conversion failed'));
          }
        }, 'image/png');
      };
      img.onerror = () => {
        reject(new Error('Image load failed'));
      };
      img.src = imageSrc;
    });
  }
  

  function base64ToBlob(base64, mimeType) {
    const byteCharacters = atob(base64.split(',')[1]);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    return new Blob([byteArray], { type: mimeType });
  }

  function formatTime(seconds) {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const secs = Math.floor(seconds % 60);
    const milliseconds = Math.floor((seconds % 1) * 1000);

    const formattedTime = `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(secs).padStart(2, '0')}.${String(milliseconds).padStart(3, '0')}`;
    return formattedTime;
  }

  async function sendImagesToServer(backgroundBlob, diskBlob, originalAudioFile, start, end) {
    const formData = new FormData();

    let audioBlob;
    if (typeof originalAudioFile === 'string' && originalAudioFile.startsWith('data:')) {
      audioBlob = base64ToBlob(originalAudioFile, 'audio/mpeg');
    } else {
      audioBlob = originalAudioFile;
    }

    formData.append('background', backgroundBlob, 'background.jpg');
    formData.append('diskImage', diskBlob, 'diskImage.jpg');
    formData.append('audio', audioBlob, 'audio.mp3');
    formData.append('start', start);
    formData.append('end', end);
    formData.append('email', userEmail);

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/v1/media/export`, {
        method: 'POST',
        body: formData
      });

      if (response.ok) {
        window.alert('Render complete, please check your email!.');
      } else {
        window.alert('Failed to export. Server error.');
      }
    } catch (error) {
      window.alert('Error sending data to server: ' + error.message);
    } finally {
      setIsExporting(false);
    }
  }

  async function captureAndExport() {
    if (!userEmail || !isValidEmail(userEmail)) {
      window.alert('Please enter a valid email address.');
      return;
    }

    if (!audioData) {
      window.alert('Please upload an audio file before exporting.');
      return;
    }

    setIsExporting(true);

    try {
      const backgroundCanvas = createColoredCanvas(diskColor);
      const backgroundBlob = await canvasToBlob(backgroundCanvas);
      const diskBlob = await imageToBlob(currentImage);
      const formattedStartTime = formatTime(updatedRegion.start);
      const formattedEndTime = formatTime(updatedRegion.end);
      const audioBlob = audioData.file;

      console.log(backgroundBlob instanceof Blob);
      console.log(diskBlob instanceof Blob);
      console.log(audioBlob instanceof Blob);
      console.log(updatedRegion.start)
      console.log(updatedRegion.end)



      await sendImagesToServer(
        backgroundBlob,
        diskBlob,
        audioBlob,
        formattedStartTime,
        formattedEndTime,
        userEmail
      );
    } catch (err) {
      window.alert('Error in capture and export: ' + err.message);
    } finally {
      setIsExporting(false);
    }
  }

  function isValidEmail(email) {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
  }

  return (
    <div className="export-container">
      <input
        type="email"
        placeholder="Enter your email"
        value={userEmail}
        onChange={(e) => setUserEmail(e.target.value)}
        className="export-textbox custom-export-textbox"
      />
      <button 
        onClick={captureAndExport} 
        className="export-button custom-export-button" 
        disabled={isExporting}
      >
        {isExporting ? 'Exporting...' : 'Export'}
      </button>
    </div>
  );  
}

export default Export;