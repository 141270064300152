import React from 'react';
import './css/ExportPage.css';
import Export from '../components/Export'

function ExportPage() {

  return (
    <div className="export-container">
      <Export/>
    </div>
  );
}

export default ExportPage;