import React from 'react';
import './css/DiskPreview.css';
import { useImageContext } from '../contexts/ImageContext';
import { useDiskColor } from '../contexts/DiskColorContext';

const DiskPreview = ({ className }) => {
  const { currentImage } = useImageContext();
  const { diskColor } = useDiskColor();

  const classes = className || 'disk-display';

  return (
    <div className={classes} style={{ backgroundColor: diskColor }}>
      <img
        src={currentImage}
        alt="Uploaded Overlay"
        className="image-overlay"
      />
    </div>
  );
};

export default DiskPreview;
