// AudioUpload.js

import React from 'react';
import './css/AudioUpload.css';
import { useAudio } from '../contexts/AudioContext'; // Import useAudio hook

const AudioUpload = () => {
    const { onAudioChange } = useAudio();
    const { audioData } = useAudio();
    const audioFileName = audioData.fileName || "Select Audio to upload";

    // Handler for file input changes
    const handleAudioUpload = async (event) => {
        const file = event.target.files[0];
        if (file) {
            const fileDataURL = await readFileDataUrl(file);
            onAudioChange(file, fileDataURL, file.name);
            console.log("File uploaded:", file); // Debugging line
        }
    };

    // Function to read file as Data URL
    const readFileDataUrl = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => resolve(reader.result);
            reader.onerror = reject;
            reader.readAsDataURL(file);
        });
    };

    return (
        <div className="audio-upload-container">
            <div className="audio-file-name-box">
                {audioFileName}
            </div>
            <label htmlFor="audio-upload" className="audio-file-upload">
                Select Audio
            </label>
            <input id="audio-upload" type="file" className="file-input" onChange={handleAudioUpload} accept="audio/mp3/flac/wav" />
        </div>
    );
};

export default AudioUpload;

