//AudioPage.js

import React from 'react';
import './css/AudioPage.css';
import AudioEditor from '../components/AudioEditor';
import AudioUpload from '../components/AudioUpload';
import { useAudio } from '../contexts/AudioContext';

function AudioPage() {
  const { audioData } = useAudio();

  return (
    <>
      <div className='audio-upload-buttons'>
        <AudioUpload />
      </div>
      <div className="audio-container">
        {audioData.file && (
          <AudioEditor />
        )}
      </div>
    </>
  );
}

export default AudioPage;
