// src/ColorsPage.js
import React from 'react';
import './css/ColorsPage.css';
import BackgroundColor from '../components/BackgroundColor';
import DiskPreview from '../components/DiskPreview';

function ColorsPage() {
  return (
    <div className="colors-page">
      <div className="colors-container">
        <div className='colors-left-column'>
          <div className='color-options-container'>
          <h2>Background Color</h2>
            <BackgroundColor
            />
          </div>
        </div>
        <div className='colors-right-column'>
          <DiskPreview className='colors-page-disk-display'/>
        </div>
      </div>
    </div>
  );
}

export default ColorsPage;
