import React, { useState, useEffect, useRef } from 'react';
import ColorThief from 'colorthief';
import { useDiskColor } from '../contexts/DiskColorContext';
import { useImageContext } from '../contexts/ImageContext';
import './css/BackgroundColor.css';

const BackgroundColor = () => {
    const { diskColor, setDiskColor } = useDiskColor();
    const { currentImage } = useImageContext();
    const [color, setColor] = useState(diskColor); // For color picker and hex input
    const [palette, setPalette] = useState([]);
    const imgRef = useRef(null);

    // Convert RGB array from Color Thief to HEX
    const rgbToHex = (rgb) => `#${rgb.map((x) => x.toString(16).padStart(2, '0')).join('')}`;

    useEffect(() => {
        const imgElement = imgRef.current;
        const updatePalette = () => {
            if (imgElement && imgElement.complete) {
                const colorThief = new ColorThief();
                const extractedPalette = colorThief.getPalette(imgElement, 5); // Gets an array of colors
                setPalette(extractedPalette.map(rgbToHex));
            }
        };

        if (imgElement) {
            imgElement.addEventListener('load', updatePalette);
            if (imgElement.complete) updatePalette();
        }

        return () => imgElement?.removeEventListener('load', updatePalette);
    }, [currentImage]);

    const handleColorChange = (event) => {
        setColor(event.target.value);
        setDiskColor(event.target.value);
    };

    const handlePaletteColorSelect = (hexColor) => {
        setColor(hexColor);
        setDiskColor(hexColor);
    };

    return (
        <div className='background-color-container'>
            <div className='background-color-text'>Pick a colour or enter the HEX code</div>
            <div className="color-picker-container">
                <input
                    type="color"
                    className="background-color-selector"
                    onChange={handleColorChange}
                    value={color}
                />
                <input
                    type="text"
                    className="hex-color-input"
                    onChange={handleColorChange}
                    value={color}
                    placeholder="#000000"
                />
            </div>
            <div className='background-color-text'>Colour suggestions from your artwork</div>
            <div className="color-palette">
                {palette.map((hexColor, index) => (
                    <button
                        key={index}
                        className="color-swatch"
                        style={{ backgroundColor: hexColor }}
                        onClick={() => handlePaletteColorSelect(hexColor)}
                        title={`Select color ${hexColor}`}
                    />
                ))}
            </div>
            <img ref={imgRef} src={currentImage} crossOrigin="anonymous" alt="Color source" style={{ display: 'none' }} />
        </div>
    );
};

export default BackgroundColor;
