import React from 'react';
import Login from '../components/Login';
import './css/LoginPage.css';
import { NavLink } from 'react-router-dom';

function LoginPage() {
    return (
        <div className="login-container">
            <div className="login-left-column">
                <div className="brand-container">
                    <NavLink to="/" className="brand-title"><h1>Render FM</h1></NavLink>
                    <p className="brand-tagline">Taking Audio Visualisations up a notch</p>
                </div>
            </div>
            <div className="login-right-column">
                <div className="login-brand-header">
                    <NavLink to="/" className="login-brand-title">Render FM</NavLink>
                </div>
                <Login /> {/* Use the Login component here */}
            </div>
        </div>
    );
}

export default LoginPage;
