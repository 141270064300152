import React from 'react';
import './css/PricingPage.css';

function PricingPage() {

  return (
    <div className="pricing-container">
      
    </div>
  );
}

export default PricingPage;