import React from 'react';
import './css/ProfilePage.css';

function ProfilePage() {


  return (
    <div className="profile-container">
      
    </div>
  );
}

export default ProfilePage;