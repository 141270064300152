// ProtectedRoute.js

import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';

const ProtectedRoute = ({ element: Element, layout: Layout = React.Fragment }) => {
    const { isAuthenticated } = useAuth();

    // If the user is authenticated, render the element within the specified layout
    // Otherwise, redirect to the login page
    return isAuthenticated() ? (
        <Layout>
            <Element />
        </Layout>
    ) : (
        <Navigate to="/login" replace />
    );
};

export default ProtectedRoute;