import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import './css/CloseButton.css'

const CloseButton = () => {
    const navigate = useNavigate();

    const handleCloseClick = () => {
        navigate('/');
    };

    return (
        <button className="close-button" onClick={handleCloseClick}>
            <FontAwesomeIcon icon={faXmark} />
        </button>
    );
};

export default CloseButton;
