import React, { useState, useEffect, useCallback } from 'react';
import { jwtDecode } from 'jwt-decode';
import './css/SettingsPage.css';
import { useAuth } from '../contexts/AuthContext';

function SettingsPage() {
  const { authToken, logout } = useAuth();
  const [userDetails, setUserDetails] = useState({
    firstName: '',
    lastName: '',
    email: '',
  });

  // Decode JWT to extract user ID
  const getUserIdFromToken = useCallback(() => {
    try {
      const decoded = jwtDecode(authToken);
      return decoded.sub; // Or the appropriate claim that holds the userId
    } catch (error) {
      console.error('Error decoding token:', error);
      return null;
    }
  }, [authToken]);

  // Fetch user details
  const fetchUserDetails = useCallback(async () => {
    const userId = getUserIdFromToken();
    if (!userId) {
      logout();
      return;
    }

    const response = await fetch(`${process.env.REACT_APP_API_URL}/v1/users/${userId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authToken}`
      }
    });

    if (response.ok) {
      const data = await response.json();
      setUserDetails({
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email
      });
    } else {
      console.error('Failed to fetch user details');
    }
  }, [authToken, logout, getUserIdFromToken]);

  useEffect(() => {
    fetchUserDetails();
  }, [fetchUserDetails]);

  // Update user details
  const updateUserDetails = async (updatedDetails) => {
    const userId = getUserIdFromToken();
    if (!userId) {
      logout();
      return;
    }

    const response = await fetch(`${process.env.REACT_APP_API_URL}/v1/users/${userId}`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authToken}`
      },
      body: JSON.stringify(updatedDetails)
    });

    if (response.ok) {
      console.log('User details updated successfully.');
      fetchUserDetails(); // Refetch user details to reflect the update
    } else {
      console.error('Failed to update user details');
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setUserDetails(prevDetails => ({ ...prevDetails, [name]: value }));
  };

  const handleSaveChanges = () => {
    updateUserDetails(userDetails);
  };

  return (
    <div className="settings-container">
      <div className="settings-header">
        <h1>Settings</h1>
      </div>
      <div className="user-details-section">
        <h3>User Details</h3>
        <div className="settings-field">
          <label>First Name</label>
          <input
            type="text"
            name="firstName"
            value={userDetails.firstName}
            onChange={handleInputChange}
          />
        </div>
        <div className="settings-field">
          <label>Surname</label>
          <input
            type="text"
            name="lastName"
            value={userDetails.lastName}
            onChange={handleInputChange}
          />
        </div>
        <div className="settings-field">
          <label>Email</label>
          <input
            type="email"
            name="email"
            value={userDetails.email}
            onChange={handleInputChange}
          />
        </div>
        <button onClick={handleSaveChanges}>Save Changes</button>
      </div>
      {/* Subscription section remains unchanged */}
      <div className="subscription-section">
        <h3>Subscription</h3>
        {/* Subscription logic here */}
      </div>
    </div>
  );
}

export default SettingsPage;
