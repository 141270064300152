import React from 'react';
import Signup from '../components/Signup'; 
import './css/SignupPage.css';
import { NavLink } from 'react-router-dom';

function SignupPage() {
    return (
        <div className="signup-container">
            <div className="signup-left-column">
                <div className="brand-container">
                    <NavLink to="/" className="brand-title"><h1>Render FM</h1></NavLink>
                    <p className="brand-tagline">Taking Audio Visualisations up a notch</p>
                </div>
            </div>
            <div className="signup-right-column">
                <div className="login-brand-header">
                    <NavLink tp="/" className="login-brand-title">Render FM</NavLink>
                </div>
                <Signup />
            </div>
        </div>
    );
}

export default SignupPage;
