import React, { createContext, useContext, useState } from 'react';

const DiskColorContext = createContext();

export const useDiskColor = () => useContext(DiskColorContext);

export const DiskColorProvider = ({ children }) => {
    const [diskColor, setDiskColor] = useState("#d8116d"); // Default color

    return (
        <DiskColorContext.Provider value={{ diskColor, setDiskColor }}>
            {children}
        </DiskColorContext.Provider>
    );
};
