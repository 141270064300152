import React, { useState, useEffect } from 'react';
import './css/Navigation.css';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBars,
  faPlay,
  faImage,
  faMusic,
  faPalette,
  faFileExport,
  faCog,
  faSignInAlt,
  faSignOutAlt,
  faUser,
} from '@fortawesome/free-solid-svg-icons';
import { useAuth } from '../contexts/AuthContext';

const Navigation = () => {
  const { isAuthenticated, logout } = useAuth();
  const [isMenuVisible, setIsMenuVisible] = useState(window.innerWidth > 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMenuVisible(window.innerWidth > 768);
    };
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const toggleMenuVisibility = () => setIsMenuVisible(!isMenuVisible);

  const MenuItem = ({ icon, label, to, action = null }) => (
    <li onClick={action}>
      <div className="menu-item-header">
        <FontAwesomeIcon icon={icon} />
        <NavLink to={to} className="menu-link">
          {label}
        </NavLink>
      </div>
    </li>
  );

  return (
    <div className="menu">
      <div className="header">
        <NavLink to="/">
          <h1>Render FM</h1>
        </NavLink>
        <div className="burger-menu" onClick={toggleMenuVisibility}>
          <FontAwesomeIcon icon={faBars} />
        </div>
      </div>
      {isMenuVisible && (
        <>
          <div className="menu-top-container">
            <nav>
              <ul className="menu-top">
                <MenuItem icon={faPlay} label="Preview" to="/" />
                <MenuItem icon={faImage} label="Images" to="/images" />
                <MenuItem icon={faPalette} label="Colours" to="/colours" />
                <MenuItem icon={faMusic} label="Audio" to="/audio" />
                <MenuItem icon={faFileExport} label="Export" to="/export" />
              </ul>
            </nav>
          </div>
          <nav>
            <ul className="menu-bottom">
              {isAuthenticated ? (
                <>
                  <MenuItem icon={faUser} label="Profile" to="/profile" />
                  <MenuItem icon={faCog} label="Settings" to="/settings" />
                  <MenuItem icon={faSignOutAlt} label="Log Out" to="/" action={logout} />
                </>
              ) : (
                <MenuItem icon={faSignInAlt} label="Log in" to="/login" />
              )}
            </ul>
          </nav>
        </>
      )}
    </div>
  );
};

export default Navigation;
