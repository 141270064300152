import React, { useState, useCallback, useEffect, useRef } from 'react';
import Cropper from 'react-easy-crop';
import getCroppedImg from './CropImage';
import './css/UploadDiskImage.css';
import { useImageContext } from '../contexts/ImageContext';

const DiskImage = ({ imageName }) => {
    const { setCurrentImage, uploadedImage, setUploadedImage, imageName: contextImageName, setImageName, crop, setCrop, zoom, setZoom } = useImageContext();
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
    const [cropSize, setCropSize] = useState({ width: 0, height: 0 });
    const cropContainerRef = useRef(null);

    const handleImageUpload = (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setUploadedImage(reader.result);
                setImageName(file.name);
            };
            reader.readAsDataURL(file);
        }
    };

    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels);
    }, []);

    const convertToCircularImage = async (croppedImage) => {
        return new Promise((resolve, reject) => {
          const img = new Image();
      
          // Function to handle the loaded image and convert to circular
          const handleImageLoad = () => {
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');
            const size = Math.min(img.width, img.height);
            canvas.width = size;
            canvas.height = size;
      
            ctx.beginPath();
            ctx.arc(size / 2, size / 2, size / 2, 0, 2 * Math.PI);
            ctx.clip();
            ctx.drawImage(img, (size - img.width) / 2, (size - img.height) / 2);
      
            canvas.toBlob((blob) => {
              if (blob) {
                resolve(URL.createObjectURL(blob));
              } else {
                reject(new Error('Canvas to Blob conversion failed'));
              }
            }, 'image/png');
          };
      
          img.onload = handleImageLoad;
          img.onerror = reject;
      
          // Check if croppedImage is a Blob
          if (croppedImage instanceof Blob) {
            img.src = URL.createObjectURL(croppedImage);
          } else if (typeof croppedImage === 'string') {
            fetch(croppedImage)
              .then(res => res.blob())
              .then(blob => {
                img.src = URL.createObjectURL(blob);
              })
              .catch(reject);
          } else {
            reject(new Error('Invalid image format'));
          }
        });
      };      

    const handleAutoCrop = useCallback(async () => {
        if (!croppedAreaPixels) {
            console.error('Cropped area pixels not set');
            return;
        }
        try {
            const croppedImageBlob = await getCroppedImg(uploadedImage, croppedAreaPixels);
            const circularImage = await convertToCircularImage(croppedImageBlob);
            setCurrentImage(circularImage);
            console.log(circularImage);
        } catch (e) {
            console.error(e);
        }
    }, [uploadedImage, croppedAreaPixels, setCurrentImage]);

    useEffect(() => {
        const updateCropSize = () => {
            if (cropContainerRef.current) {
                const { width, height } = cropContainerRef.current.getBoundingClientRect();
                setCropSize({
                    width: width * 0.8, // 80% of the container width
                    height: height * 0.8 // 80% of the container height
                });
            }
        };

        window.addEventListener('resize', updateCropSize);
        updateCropSize();

        return () => window.removeEventListener('resize', updateCropSize);
    }, []);

    useEffect(() => {
        if (uploadedImage && croppedAreaPixels) {
            handleAutoCrop();
        }
    }, [uploadedImage, croppedAreaPixels, handleAutoCrop]);

    return (
        <div className="disk-image-container">
            <div className="left-column">
                <div className='upload-image-container'>
                    <h2>Upload Image</h2>
                    <div className="image-file-name-box">
                        {contextImageName}
                    </div>
                    <label htmlFor="image-upload" className="image-file-upload">
                        Choose Image
                    </label>
                    <input id="image-upload" type="file" className="file-input" onChange={handleImageUpload} accept="image/*" />
                </div>
            </div>
            <div className='right-column'>
                <div className="crop-container" ref={cropContainerRef}>
                    <Cropper
                        image={uploadedImage}
                        crop={crop}
                        zoom={zoom}
                        aspect={1}
                        cropSize={cropSize}
                        onCropChange={setCrop}
                        onZoomChange={setZoom}
                        onCropComplete={onCropComplete}
                    />
                </div>
            </div>
        </div>
    );
};

export default DiskImage;
