import React from 'react';
import './css/PreviewPage.css';
import DiskPreview from '../components/DiskPreview';

function PreviewPage () {

  return (
    <div className="preview-container">
      <DiskPreview />
    </div>
  );
}

export default PreviewPage;
