// PageLayout.js

import React from 'react';
import Navigation from '../components/Navigation';
import './css/PageLayout.css';
import CloseButton from '../components/CloseButton';

const Layout = ({ children }) => {
    return (
        <div className="app-container">
            <Navigation />
            <div className="content">
                <div><CloseButton /></div>
                {children}
            </div>
        </div>
    );
};

export default Layout;
