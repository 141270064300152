import React, { createContext, useState, useContext } from 'react';
import defaultSpinningDisk from "../images/defaultSpinningDisk.jpg"; // Adjust the path as necessary

const ImageContext = createContext();

export const useImageContext = () => useContext(ImageContext);

export const ImageProvider = ({ children }) => {
    const [currentImage, setCurrentImage] = useState(defaultSpinningDisk);
    const [uploadedImage, setUploadedImage] = useState(defaultSpinningDisk); // Set defaultSpinningDisk as initial value
    const [imageName, setImageName] = useState('example.jpg'); // Update this to reflect the default image name
    const [crop, setCrop] = useState({ x: -1.6917990673332923, y: 1.4229710218343143 });
    const [zoom, setZoom] = useState(1.5038077539391836);

    return (
        <ImageContext.Provider value={{ currentImage, setCurrentImage, uploadedImage, setUploadedImage, imageName, setImageName, crop, setCrop, zoom, setZoom }}>
            {children}
        </ImageContext.Provider>
    );
};