import React from 'react';
import './css/DesignsPage.css';
import AudioEditor from '../components/AudioEditor';

function DesignsPage() {


  return (
    <div>
        <AudioEditor/>
    </div>
  );
}

export default DesignsPage;
