import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import './css/Login.css';
import { NavLink } from 'react-router-dom';

function Login() {
  const { login } = useAuth();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
      email: '',
      password: '',
  });

  const handleChange = (e) => {
      setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
      e.preventDefault();
      try {
          const response = await fetch(`${process.env.REACT_APP_API_URL}/v1/auth/login`, {
              method: 'POST',
              headers: {
                  'Content-Type': 'application/json',
              },
              body: JSON.stringify(formData),
          });
          const data = await response.json();
          if (response.ok) {
              // Assuming the token is in data.tokens.access.token
              login(data.tokens.access.token); // Store the token
              console.log('Login successful', data);
              navigate('/'); // Redirect to homepage
          } else {
              console.error('Login failed', data.message);
              // Handle error, show a message to the user
          }
      } catch (error) {
          console.error('Error during login', error);
          // Handle network error, show a message to the user
      }
  };

    return (
        <form className="login-form" onSubmit={handleSubmit}>
            <div className="login-form-header">
                <h2 className="login-form-title">Log in</h2>
            </div>
            <label htmlFor="email" className="login-form-label">Email</label>
            <input
                id="email"
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                className="login-form-input"
            />
            <label htmlFor="password" className="login-form-label">Password</label>
            <input
                id="password"
                type="password"
                name="password"
                value={formData.password}
                onChange={handleChange}
                className="login-form-input"
            />
            <button type="submit" className="login-form-button">Log in</button>
            <div className="login-form-footer">
                        <NavLink to="/login" className="login-form-link left-link">Forgot your password?</NavLink>
                        <NavLink to="/signup" className="login-form-link right-link">Sign Up</NavLink>
            </div>
        </form>
    );
}

export default Login;
