import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
import { jwtDecode } from 'jwt-decode';
import { useNavigate } from 'react-router-dom';

const AuthContext = createContext(null);

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
    const [authToken, setAuthToken] = useState(localStorage.getItem('authToken'));
    const navigate = useNavigate();

    const logout = useCallback(() => {
        setAuthToken(null);
        localStorage.removeItem('authToken');
        localStorage.removeItem('refreshToken'); // Also remove the refresh token
        navigate('/');
    }, [navigate]);

    const login = useCallback((token) => {
        setAuthToken(token);
        localStorage.setItem('authToken', token);
    }, []);

    const refreshToken = useCallback(async () => {
        const refreshToken = localStorage.getItem('refreshToken'); // Get the refresh token from storage
        if (!refreshToken) {
            return null;
        }

        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/v1/auth/refresh-tokens`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ refreshToken }),
            });

            if (response.ok) {
                const data = await response.json();
                localStorage.setItem('authToken', data.accessToken); // Update the access token
                localStorage.setItem('refreshToken', data.refreshToken); // Update the refresh token if necessary
                login(data.accessToken); // Use the login callback here
                return data.accessToken;
            } else {
                console.error('Unable to refresh token');
                return null;
            }
        } catch (error) {
            console.error('Error refreshing auth token:', error);
            return null;
        }
    }, [login]); // Include login in the useCallback dependency array

    useEffect(() => {
        const checkTokenExpiry = async () => {
            if (authToken && isTokenExpired(authToken)) {
                const newToken = await refreshToken();
                if (!newToken) {
                    logout();
                }
            }
        };

        checkTokenExpiry(); // Also call it on mount to check the token immediately

        const interval = setInterval(checkTokenExpiry, 5 * 60 * 1000); // Check every 5 minutes
        return () => clearInterval(interval); // Cleanup on unmount
    }, [authToken, logout, refreshToken]); // Include refreshToken in the dependency array

    const isTokenExpired = (token) => {
        try {
            const decoded = jwtDecode(token);
            return decoded.exp < Date.now() / 1000;
        } catch {
            return true;
        }
    };

    return (
        <AuthContext.Provider value={{ authToken, isAuthenticated: !!authToken, login, logout, refreshToken }}>
            {children}
        </AuthContext.Provider>
    );
};

export default AuthProvider;
