import React from 'react';
import './css/PageLayout.css'; // Ensure you have the correct path

const Layout = ({ children }) => {
    return (
        <div className="app-container">
            {children}
        </div>
    );
};

export default Layout;

