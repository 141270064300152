import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext'; // Import useAuth
import './css/Signup.css';
import { NavLink } from 'react-router-dom';

function Signup() {
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        password: '',
    });

    const { login } = useAuth(); // Use the login function from AuthContext
    const navigate = useNavigate();

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
          // Signup request
          const signupResponse = await fetch(`${process.env.REACT_APP_API_URL}/v1/auth/register`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(formData),
          });

          if (signupResponse.ok) {
            console.log('Signup successful');
            // Assuming you get the necessary token directly after signup
            // If not, you may need to separately call a login endpoint as shown in the Login component
            const loginResponse = await fetch(`${process.env.REACT_APP_API_URL}/v1/auth/login`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email: formData.email, password: formData.password }),
            });
            const data = await loginResponse.json();
            if (loginResponse.ok) {
                login(data.tokens.access.token); // Log the user in
                navigate('/'); // Redirect to homepage
            } else {
                console.error('Login failed', data.message);
                // Handle login error, show a message to the user
            }
          } else {
            const data = await signupResponse.json();
            console.error('Signup failed', data.message);
            // Handle signup error, show a message to the user
          }
        } catch (error) {
          console.error('Error during signup', error);
          // Handle network error, show a message to the user
        }
      };

    return (
        <form className="signup-form" onSubmit={handleSubmit}>
            <div className="signup-form-header">
                <h2 className="signup-form-title">Sign Up</h2>
            </div>
            <label htmlFor="first-name" className="signup-form-label">First Name</label>
            <input
                id="first-name"
                type="text"
                name="firstName"
                value={formData.firstName}
                onChange={handleChange}
                className="signup-form-input"
            />
            <label htmlFor="last-name" className="signup-form-label">Last Name</label>
            <input
                id="last-name"
                type="text"
                name="lastName"
                value={formData.lastName}
                onChange={handleChange}
                className="signup-form-input"
            />
            <label htmlFor="email" className="signup-form-label">Email</label>
            <input
                id="email"
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                className="signup-form-input"
            />
            <label htmlFor="password" className="signup-form-label">Password</label>
            <input
                id="password"
                type="password"
                name="password"
                value={formData.password}
                onChange={handleChange}
                className="signup-form-input"
            />
            <button type="submit" className="signup-form-button">Sign Up</button>
            <div className="signup-form-footer">
              <div></div>
              <NavLink to="/login" className="signup-form-link right-link">Login</NavLink>
            </div>
        </form>
    );
}

export default Signup;
