import PreviewPage from '../pages/PreviewPage';
import DesignsPage from '../pages/DesignsPage';
import ImagesPage from '../pages/ImagesPage';
import ColorsPage from '../pages/ColorsPage';
import AudioPage from '../pages/AudioPage';
import ExportPage from '../pages/ExportPage';
import ProfilePage from '../pages/ProfilePage';
import SettingsPage from '../pages/SettingsPage';
import LoginPage from '../pages/LoginPage';
import SignupPage from '../pages/SignupPage';
import PricingPage from '../pages/PricingPage';
import HomeLayout from '../layouts/HomeLayout';
import PageLayout from '../layouts/PageLayout';
import LoginLayout from '../layouts/LoginLayout';

const routes = [
    { path: '/', element: <HomeLayout><PreviewPage /></HomeLayout>, name: 'Preview' },
    { path: '/designs', element: <PageLayout><DesignsPage /></PageLayout>, name: 'Designs' },
    { path: '/images', element: <PageLayout><ImagesPage /></PageLayout>, name: 'Images' },
    { path: '/colours', element: <PageLayout><ColorsPage /></PageLayout>, name: 'Colors' },
    { path: '/audio', element: <PageLayout><AudioPage /></PageLayout>, name: 'Audio' },
    { path: '/export', element: <PageLayout><ExportPage /></PageLayout>, name: 'Export' },
    { path: '/profile', element: <PageLayout><ProfilePage /></PageLayout>, name: 'Profile', protected: true },
    { path: '/settings', element: <PageLayout><SettingsPage /></PageLayout>, name: 'Settings' },
    { path: '/login', element: <LoginLayout><LoginPage /></LoginLayout>, name: 'Login' },
    { path: '/signup', element: <LoginLayout><SignupPage /></LoginLayout>, name: 'Signup' },
    { path: '/pricing', element: <PricingPage />, name: 'Pricing' },
  ];  

export default routes;
