//AudioContext.js

import React, { createContext, useContext, useState } from 'react';

const AudioContext = createContext();

export const AudioProvider = ({ children }) => {
  const [audioData, setAudioData] = useState({
    file: null,
    fileDataURL: null,
    fileName: '',
  });
  const [updatedRegion, setUpdatedRegion] = useState(null); // Initially, there's no updated region

  const onAudioChange = (file, fileDataURL, fileName) => {
    setAudioData({ file, fileDataURL, fileName });
  };

  const onUpdateRegion = (region) => {
    setUpdatedRegion(region); // Update the region in the context
  };

  return (
    <AudioContext.Provider value={{ audioData, onAudioChange, updatedRegion, onUpdateRegion }}>
      {children}
    </AudioContext.Provider>
  );
};

export const useAudio = () => useContext(AudioContext);

