import React, { useCallback, useEffect, useRef, useState, useMemo } from 'react';
import { WaveSurfer, WaveForm, Region } from 'wavesurfer-react';
import RegionsPlugin from 'wavesurfer.js/dist/plugins/regions';
import TimelinePlugin from 'wavesurfer.js/dist/plugins/timeline';
import ZoomPlugin from 'wavesurfer.js/dist/plugins/zoom';
import { useAudio } from '../contexts/AudioContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay, faPause } from '@fortawesome/free-solid-svg-icons';
import './css/AudioEditor.css';

const DEFAULT_ZOOM_LEVEL = 0;
const DEFAULT_REGION = {
  id: "region",
  start: 0,
  end: 30,
  drag: true,
  resize: false,
  color: "rgba(0, 0, 0, .5)",
  data: { systemRegionId: 31 }
};

const AudioEditor = () => {
  const { audioData, updatedRegion, onUpdateRegion } = useAudio();
  const [timelineVis] = useState(true);
  const [isLoaded, setIsLoaded] = useState(false);
  const [zoomLevel] = useState(DEFAULT_ZOOM_LEVEL);
  const [region, setRegion] = useState(updatedRegion || DEFAULT_REGION);
  const wavesurferRef = useRef();
  const containerRef = useRef();

  const initializeWaveSurfer = useCallback(() => {
    if (audioData.file) {
      wavesurferRef.current.loadBlob(audioData.file);
      wavesurferRef.current.on("ready", () => {
        console.log("WaveSurfer is ready");
        setIsLoaded(true);
        wavesurferRef.current.zoom(zoomLevel);
      });
    }
  }, [audioData.file, zoomLevel]);

  const plugins = useMemo(() => [
    {
      key: "region",
      plugin: RegionsPlugin,
    },
    timelineVis && {
      key: "top-timeline",
      plugin: TimelinePlugin,
      options: {
        height: 20,
        insertPosition: 'beforebegin',
        style: { color: '#2D5B88' }
      }
    },
    timelineVis && {
      key: "bottom-timeline",
      plugin: TimelinePlugin,
      options: {
        height: 20,
        style: { color: '#6A3274' }
      }
    },
    {
      plugin: ZoomPlugin,
    }
  ].filter(Boolean), [timelineVis]);

  const resizeWaveform = useCallback(() => {
    try {
      if (wavesurferRef.current) {
        wavesurferRef.current.zoom(DEFAULT_ZOOM_LEVEL);
      }
    } catch (error) {
      console.error("Failed to reset zoom level:", error.message);
    }
  
    const uploadButtonsDiv = document.querySelector('.audio-upload-buttons');
    if (!uploadButtonsDiv) return;
    const containerWidth = uploadButtonsDiv.offsetWidth;
    const waveformDiv = document.getElementById('waveform');
    if (waveformDiv) {
      waveformDiv.style.maxWidth = `${containerWidth}px`;
    }
  }, []);

  useEffect(() => {
    window.addEventListener('resize', resizeWaveform);
    resizeWaveform();
    return () => window.removeEventListener('resize', resizeWaveform);
  }, [resizeWaveform]);

  useEffect(() => {
    if (wavesurferRef.current) initializeWaveSurfer();
    return () => wavesurferRef.current?.un('ready', initializeWaveSurfer);
  }, [initializeWaveSurfer]);

  const handleWSMount = useCallback((waveSurfer) => {
    wavesurferRef.current = waveSurfer;
    initializeWaveSurfer();
  
    // Listen to the dragstart event
    wavesurferRef.current.on('dragstart', (relativeX) => {
      console.log('Dragging started at relative X position:', relativeX);
    });
  }, [initializeWaveSurfer]);

  const handleRegionUpdate = useCallback((updatedRegion) => {
    const newRegion = { ...region, start: updatedRegion.start, end: updatedRegion.end };
    setRegion(newRegion);
    onUpdateRegion(newRegion);
    if (wavesurferRef.current.isPlaying()) {
      wavesurferRef.current.pause();
      setTimeout(() => {
        wavesurferRef.current.seekTo(updatedRegion.start / wavesurferRef.current.getDuration());
        wavesurferRef.current.play(updatedRegion.start, updatedRegion.end);
      }, 50);
    }
  }, [region, onUpdateRegion]);

  useEffect(() => {
    const onAudioprocess = () => {
      if (wavesurferRef.current.getCurrentTime() >= region.end) {
        wavesurferRef.current.pause();
        wavesurferRef.current.seekTo(region.start / wavesurferRef.current.getDuration());
      }
    };
    wavesurferRef.current?.on('audioprocess', onAudioprocess);
    return () => wavesurferRef.current?.un('audioprocess', onAudioprocess);
    
  }, [region.start, region.end]);

  const play = useCallback(() => {
    if (wavesurferRef.current && isLoaded) {
      wavesurferRef.current.play(region.start, region.end);
      wavesurferRef.current?.seekTo(region.start / wavesurferRef.current.getDuration());
    }
  }, [region.start, region.end, isLoaded]);

  const stop = useCallback(() => {
    wavesurferRef.current?.pause();
    wavesurferRef.current?.seekTo(region.start / wavesurferRef.current.getDuration());
  }, [region.start]);

  return (
    <div className="waveform-container" ref={containerRef}>
      <WaveSurfer
        plugins={plugins}
        onMount={handleWSMount}
        cursorColor="transparent"
        container="#waveform"
        isInteractive={true}
        autoCenter={false}
        progressColor="#632944"
        height={400}
        interact={false}
        dragToSeek={true}
        fillParent={true}
        autoScroll={false}
        barHeight={0.8}
        hideScrollbar={false}
      >
        <WaveForm id="waveform">
          {isLoaded && <Region onUpdateEnd={handleRegionUpdate} key={region.id} {...region} />}
        </WaveForm>
      </WaveSurfer>
      <div className="controls">
        <div className="button-group">
          <button onClick={play}><FontAwesomeIcon icon={faPlay} /></button>
          <button onClick={stop}><FontAwesomeIcon icon={faPause} /></button>
        </div>
        </div>
      </div>
  );
};

export default AudioEditor;
