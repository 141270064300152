// Layout.js
import React from 'react';
import Navigation from '../components/Navigation';
import './css/PageLayout.css';

const Layout = ({ children }) => {
    return (
        <div className="app-container">
            <Navigation />
            <div className="content">
                {children}
            </div>
        </div>
    );
};

export default Layout;
