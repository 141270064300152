import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import { AudioProvider } from './contexts/AudioContext';
import { DiskColorProvider } from './contexts/DiskColorContext';
import { AuthProvider } from './contexts/AuthContext';
import { ImageProvider } from './contexts/ImageContext';
import routes from './routes/routes';
import ProtectedRoute from './components/ProtectedRoute';

function App() {
  return (
    <Router>
      <AuthProvider>
        <ImageProvider>
          <AudioProvider>
            <DiskColorProvider>
              <Routes>
                {routes.map((route, index) => {
                  // For protected routes, wrap the element with ProtectedRoute
                  const Element = route.protected ?
                    <ProtectedRoute element={route.element} layout={route.layout || React.Fragment} /> :
                    React.createElement(route.layout || React.Fragment, {}, route.element);

                  // Use React Router's Route component directly
                  return <Route key={index} path={route.path} element={Element} />;
                })}
              </Routes>
            </DiskColorProvider>
          </AudioProvider>
        </ImageProvider>
      </AuthProvider>
    </Router>
  );
}


export default App;
