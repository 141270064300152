import React from 'react';
import './css/ImagesPage.css';
import UploadDiskImage from '../components/UploadDiskImage';

function ImagesPage() {

  return (
    <UploadDiskImage />
  );
}

export default ImagesPage;